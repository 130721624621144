.setup {
  ul, ol {
    li {
      color: $gray;

      span {
        color: $blue;
      }
    }
  }
}
