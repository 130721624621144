.transferModal {
  h2 {
    font-size: 1em;
    text-align: center;
  }

  button {
    display: inline-block;
    padding: 10px 20px !important;
    margin: 1em;
    border: 1px solid $blue;
    background: $white;
    color: $blue;
  }

  button:hover {
    border-color: $light-gray;
  }
}
