div.success {
  width: 100%;
  max-width: 350px;
  margin: 1em auto;
  padding: 1em;
  text-align: center;
  position: relative;
  border: 1px solid $blue;
  border-radius: 12px;

  strong {
    font-weight: 500 !important;
    color: $blue;
  }
}
