$gray: #919191;
$light-gray: #f7f7f7;
$custom-gray: #E6DED6;
$blue: #4B5CF0;
$white: #ffffff;
$red: #E02F2F;
$light-red: rgba(232, 99, 99, 0.2);

@import 'layout';
@import 'header';
@import 'footer';
@import 'containers';
@import 'columns';
@import 'extends';
@import 'errorMessages';
@import 'typography';
@import 'rlogin';
@import 'forms';

@import 'home/index';
@import 'registration/registration-page';
@import 'registration/commit-step';
@import 'setupTab/setup';
@import 'admin/index';
@import 'components/index';
@import 'resolve/index';
