.results {
  h2 {
    text-align: left;
    font-weight: 500 !important;
    font-size: 30px;
  }

  .searchResults {
    border: 1px solid $gray;
    border-radius: 12px;
    padding: 25px 0 20px 0;
    margin-bottom: 50px;

    h3 {
      text-align: left;
      margin: 10px 0 0 15px;
      padding: 0;
    }

    p {
      font-size: 20px;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    p.blue {
      margin-top: 10px;
    }

    p.searchAnother {
      font-size: 12px;
      margin-top: -6px;
      text-align: center;
    }

    p.cost {
      margin-top: -6px;
      span.rifPrice {
        font-size: 32px;
        font-weight: 500 !important;
      }

      span.year {
        font-size: 24px;
      }
    }

    button {
      display: inline-block;
      margin-top: 0;
      background-color: $blue;
      color: $white;
      border-radius: 15px;
      padding: 10px 20px !important;
      border: 1px solid $blue;
    }


    button:hover {
      color: $blue;
      background-color: $white;
      border: 1px solid $blue !important;
    }
  }

  .searchResults.notAvailable {
    h3 {
      margin-top: 0;
    }

    p.status {
      color: $red;
    }
  }
}

// mobile styles
@media (max-width: 767.98px) {
  .results h2 {
    text-align: center;
    margin-top: 15%;
  }

  .searchResults {
    * {text-align: center !important;}
    margin-left: 15px;
    margin-right: 15px;
  }
}
