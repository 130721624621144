.rentalPeriod {
  .counter {
    .input-group-append {
      .btn {
        background: none;
        border: none;
        color: $gray;
        font-size: 1.25em;
        padding: 10px 20px !important;
      }
      .btn:hover {
        box-shadow: none;
        color: $blue;
      }
    }
    .form-control {
      background: none;
      border: 1px solid #e5e5e5;
      border-radius: 8px !important;
      text-align: center;
      font-size: 1.5em;
      color: $blue;
      font-weight: 500 !important;
    }
  }

  .price {
    h3 {
      color: $blue;
      margin-bottom: 0;
    }
    .box {
      max-width: 125px;
      margin: 1em auto;
      padding: .25 0;
      border: 1px solid $blue;
      border-radius: 5px;
      background-color: $light-gray;
    }
    p.rifPrice {
      font-size: 2em;
      font-weight: 500 !important;
      margin: .25em 0 0 0 ;
    }
    p.usdPrice {
      margin-top: 0;
    }
  }
}
