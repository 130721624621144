.copyButton {
  display: inline-block;

  button {
    border: none;
    background-color: rgba(0,0,0,0) !important;
    margin-left:.25em;

    img {
      width: 25px;
    }
  }

  button:hover {
    border: none !important;
  }

  .response {
    color: $gray !important;
    font-weight: 300 !important;
  }

  .copyText {
    position: absolute;
    left: -8000px;
  }
}
