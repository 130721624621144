.admin {

  @import 'domainInfo';
  @import 'subdomains';
  @import 'address';
  @import 'reverse';
  @import 'resolver';

  margin-top: 75px;

  .btn-primary:not(:disabled):not(.disabled).active {
    background-color: $light-gray;
    border-color: $gray;
    color: $gray;
  }

  .toggleSwitch {
    text-align: right;

    button:hover {
      border: none !important;
    }
  }

  .custom-switch {
    * {
      cursor: pointer;
    }
  }

  color: $gray;
  text-align: left;

  p {
    font-size: .9em;
  }

  h1 {
    font-size: 20px;
    color: $blue;
    margin-top: 20px;
  }

  h1:first-letter, h2:first-letter {
    text-transform: uppercase;
  }

  h2 {
    font-size: 20px;
    color:$blue;
  }

  .reclaim {
    button {
      border-radius: 15px;
      padding:10px 20px !important;
    }
  }
}

.capitalize {
  text-transform: capitalize;
}

// mobile styles
@media (max-width: 767.98px) {
  .admin {
    h1 {
      margin-top: 10px;
    }
  }
}

@import 'transferModal';
@import 'leftnav';
