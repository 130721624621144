.navbar-expand-md {
  background-color: #4B5CF0;
  // box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  padding: 16px 0;

  .indicator {
    color: #BFEEFF;
    opacity: 1 !important;
    span {
      position: relative;
      margin-left: 8px;
      padding-left: 3px;
    }
    span::before {
      position: absolute;
      right: 98%;
      top: 45%;
      transform: translate(-5px, -50%);
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #40d512;
    }
  }

  .indicator.network-error {
    span::before {
      background-color:#dc3545;
    }
  }

  .navbar-nav .nav-link {
    color: #BFEEFF;
  }

  .navbar-nav .nav-link:hover {
    font-weight: normal;
    color: #BFEEFF;
  }

  .nav-link.btn {
    background: none;
    border: none;
    border-radius: 0;
    font-weight: 300 !important;
    font-size: 16px;
  }

  .nav-link.btn:hover, .nav-link.btn:active {
    color: #000000 !important;
    background: none !important;
    border:none;
    box-shadow: none;
    font-weight: 500 !important;
  }

  // language button
  .btn-primary {
    font-weight: 300 !important;
  }
  .btn-primary:hover:not([disabled]) {
    background: none !important;
    border-color: $blue !important;
    color: #BFEEFF;
  }

  // start/login button
  .btn-primary:not([disabled]).start, .btn-primary.start:hover:not([disabled])  {
    border: 1px solid #BFEEFF !important;
  }
}

// mobile styles
@media (max-width: 767.98px) {
  .navbar {
    position: relative !important;
  }

  .navbar-brand {
    margin-left: 10px;
  }

  .indicator {
    margin-left: 0 !important;
  }

  .nav-link {
    padding-left: 15px !important;
  }
}
