.major-section {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

.minor-section {
  margin-top: .75em;
  margin-bottom: .75em;
}

.break-above {
  margin-top: .75em;
}

.large-break-above {
  margin-top: 3em;
}

.break-below {
  margin-bottom: .75em;
}
