.checksumError, .invalid-feedback {
  font-size: 100%;
  text-align: center;

  div.title {
    font-weight: 500 !important;
  }
  span {
    display: inline-block;
    margin: .5em 0;
    cursor: pointer;
  }

  span::after {
    display: inline-block;
    margin: 0 .5em;
    content: '?';
    background-color: #E5E5E5;
    color: $red;
    font-weight: 500 !important;
    width: 1.5em;
    height: 1.5em;    
    border-radius: 1em;
  }
}

div.checksumError {
  width: 100%;
  text-align: center;
  margin: 2em 0;

  span {
    color: $red;
  }
}
