footer {
  .footer-top {
    background-color: $custom-gray;
    padding: 50px 0;

    text-align: center;

    img {
      height: 75px;
    }

    h2 {
      font-size: 22px;
      font-weight: 500 !important;
      color: $blue;
      text-transform: capitalize;
    }

    ul {
      margin: 1em 0 0 0;
      padding: 0;

      li {
        list-style-type:none;
        text-transform: capitalize;

        a {
          color: #919191;
        }
      }
    }

    p {
      padding: 50px 0;
    }
  }
}

footer::before {
  content: '';
  width: 100%;
  height: 200px;
  background: $custom-gray;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  position: relative;
  top: 125px;
}

// mobile styles
@media (max-width: 767.98px) {
  footer {
    margin-top: -75px;

    .footer-top {
      padding-top: 0 !important;
    }
  }
}
