.yourAddress {
  .addNew {
    button.add, input, select {
      display: block;
      width: 100%;
    }

    button.add {
      background: $blue;
      padding: 10px !important;
      color: $white;
      border-radius: 15px;
    }

    select {
      -webkit-appearance:none;
      text-align: left;
      color: $gray;
      padding: 10px 10px;
      border: 1px solid $gray;
      border-radius: 5px;
      background-image: url("/assets/img/caret-down.svg");
      background-position: right 15px center;
      background-repeat: no-repeat;
      background-size: 12px 8px;
    }

    input {
      border: 1px solid $gray;
      border-radius: 12px;
      padding: 10px;
    }

    input::-webkit-input-placeholder {
      text-align: center;
      color: #E5E5E5;
      font-size: .9em;
    }
  }

  .addressInput {
    .label {
      text-align: left !important;
      img {
        margin-right: 10px;
        height: 20px;
        width: 20px;
      }
    }
  }
}

.btn-primary.migrate {
  border-radius: 15px;
  padding: .75em 2em !important;
}
