.register {
  text-align: center;

  h1 {
    margin: 25px 0;
    font-size: 16px;

    .domain {
      font-size: 24px;
      color: $blue;
      font-weight: 700 !important;
    }
  }

  .list-inline {
    margin-bottom: 4em;
    li {
      display:inline-block;
      margin: 1em .5em;
      min-width: 222px;
    }
    div.btn, div.btn:hover {
      padding: 1em 3em !important;
      border-radius: 5px;
      width: 100%;
      background-color: #fff !important;
      color: $blue;
    }
    div.btn.btn-active {
      background-color: $gray !important;
      color: #ffffff;
    }
  }

  .commitConfirmedBox {
    max-width: 200px;
    margin: 0 auto;
    padding: 1em;
    border: 1px solid $blue;
    border-radius: 8px;
    p {
      margin: .5em 0 0 0 ;
    }
  }

  .btn {
    padding: .5em 2em !important;
    border-radius: 8px;
  }

  .btn:hover {
    border-color: $gray !important;
    box-shadow: none !important;
  }

  .success {
    button.close {
      display: none;
    }
  }
}


// tablet size
@media (max-width: 1199px) {
  .register {
    .list-inline li {
      min-width: auto !important;
    }
  }
}

// mobile styles
@media (max-width: 767.98px) {
  .register {
    .list-inline {
      margin-bottom: 15px;

      li {
        margin:0 0 5px 0 !important;
        display: block;
      }
    }

    .next-steps {
      div {
        margin-bottom: 10px;
      }
    }
  }
}
