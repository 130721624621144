.loginDropdown {
  position: relative;

  .popup {
    position: absolute;
    width: 300px;
    right: 0px;
    top: 50px;
    background-color: $custom-gray;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    list-style-type: none;
    text-align: center;
    margin: 0;
    padding: 0;

    li {
      padding: 10px 15px;
      border-bottom: 1px solid $light-gray;
      margin: 0;

      button {
        display: block;
        width: 100%;
        border: none;
        background-color: $custom-gray;
        font-size: 14px;
        text-align: left;
      }

      button:hover {
        border:none !important;
        font-weight: 400 !important;
        color: $gray;
      }
    }

    li:last-child {
      border-bottom: none;
    }

    li.current {
      button {
        color: $blue;
      }
    }

    li.loginForm button {
      text-align: center;
    }

    li.disconnect {
      button, button:hover {
        font-weight: 400 !important;
        text-align: center;
        display: inline-block;
        width: auto;
        border: 1px solid $gray !important;
        border-radius: 10px;
        padding: 3px 17px;
      }

      button:hover {
        background-color: $light-gray;
      }
    }

    .loginForm {
      padding: 10px 15px;

      button.active {
        color: $blue;
      }

      h3 {
        font-size: 14px;
      }

      .rskinput {
        background-color: $white;
        border: 1px solid #919191;
        border-radius: 5px;
        padding: 5px;

        input {
          border:none;
        }

        input:focus {
          outline: none
        }

        span {
          color: $blue;
        }
      }

      .btn.btn-primary {
        margin: 10px auto 0 auto !important;
        background-color: #919191;
        border-color: #919191;
        border-radius: 15px;
        width: auto;
      }

      .btn.btn-primary:hover {
        background-color: $blue !important;
        border-color: $blue;
      }

      p.error {
        color: $red;
      }
    }
  }
}

// mobile styles
@media (max-width: 767.98px) {
  .loginDropdown {
    .popup {
      position: relative;
      top: 10px;
      width: 90%;
    }
  }
}
