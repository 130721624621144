.addressInput {
  .row {
    width: 100%;
    margin: 0;
  }

  button:first-letter {
    text-transform: uppercase;
  }

  button:hover {
    box-shadow: none !important;
    border: 1px solid $gray !important;
    color: $gray;
  }

  .view {
    border: 1px solid $gray;
    padding: 10px 0px;
    width: 100%;
    font-size: .9em;
    margin: 0 15px !important;

    .label {
      color: $blue;
      text-align: center;
      overflow: hidden;
    }

    .value {
      text-align: center;
    }

    .value-prefix {
      text-transform: capitalize;
    }

    .options {
      text-align: right;
    }

    button, button:hover {
      border: none !important;
      background: $white;
      margin: 0 !important;
      padding: 1px 6px !important;
    }
  }

  div.edit {
    width: 100%;
    margin: 1em 0;

    .editLabel {
      text-transform: capitalize;
      text-align: right;
      font-size: .9em;
      padding-top: 12px;
    }

    input, input:focus {
      width: 100%;
      border: 1px solid $gray !important;
      padding:10px 20px;
      border-radius: 12px;
      color: $gray;
    }

    button {
      padding:10px 20px !important;
      margin-left: 5px;
      border-radius: 15px;
      width: 47%
    }

    button.cancel {
      border:1px solid $gray;
      background: $white;
      color: $gray;
      font-weight: 300 !important;
    }
  }

  div.settingsMenu>div {
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    padding: 25px;
    margin: -10px 0 0 50px;
    text-align: center;
    max-width: 250px;

    button {
      border-radius: 20px;
    }

    button:hover {
      color: $blue;
      background-color: $white;
    }
  }
}
