.rlogin-modal-lightbox {
  z-index: 2000 !important;
}

.rlogin-header3 {
  margin: 18px 0;
}

.rlogin-paragraph {
  margin: 12px 0;
}
