.toggleSwitch {
  .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 26px;
    margin: 0 8px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px solid $gray;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 34px;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 18px;
      width: 18px;
      left: 4px;
      bottom: 3px;
      background-color: $blue;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 50%;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(22px);
      -ms-transform: translateX(22px);
      transform: translateX(22px);
    }
  }

  button {
    color: $gray;
    font-size: .95em;
    border: none;
    background-color: $white;
  }

  button.active {
    color: $blue;
  }
}
