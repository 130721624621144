.reverse {
  .addressInput {
    .label {
      overflow: visible;
    }
    .value {
      text-align: right;
    }
  }  
}
