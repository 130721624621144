@import 'searchBox';
@import 'searchResults';

.home {
  max-width: 100%;
  padding: 0;

  .search.container {
    max-width: 100%;
    text-align: center;
    background-color: $blue;

    .row {
      padding-top: 50px;
    }

    h1, p {
      color: $custom-gray;
      text-align: center;
    }

    h1 {
      font-size: 36px;
      font-weight: 500 !important;
    }

    p.lead {
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .spacer::before {
    content: '';
    width: 100%;
    height: 80px;
    background: $blue;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    position: relative;
  }

  .white {
    * {text-align: center;}
    margin-top: 50px;
    margin-bottom: 50px;

    h2 {
      color: $blue;
      font-weight: 700 !important;
      margin-bottom: 15px;
    }

    p {
      margin: 10px auto;
      max-width: 280px;
    }

    .imageWraper {
      min-height: 250px;
    }
  }

  .supported-wallets {
    h2 {
      text-align: center;
    }

    .wallet-carousel {
      padding-bottom: 50px;

      .carousel-control-prev, .carousel-control-next {
        width: auto;
        background-image:none;
      }

      .carousel-control-next-icon:after {
        content: '>';
        font-size: 55px;
        color: #666666;
      }

      .carousel-control-prev-icon:after {
        content: '<';
        font-size: 55px;
        color: #666666;
      }

      .carousel-indicators li {
        background-color: #666666;
      }

      .carousel-inner {
        a {
          display: block;

          p {
            text-align: center;
          }

          .image-container {
            display: block;
            margin: 0 auto;
            width: 140px;
            height: 200px;
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;
            opacity: .5;
          }
        }

        a:hover {
          p {
            color: $blue;
          }

          .image-container {
            opacity: 1;
          }
        }
      }
    }
  }

  .developer {
    h2 {
      text-align: center;
    }

    p.lead {
      text-align: center;
    }

    .row {
      width: 100%
    }

    .cta div {
      text-align: center;
      margin-bottom: 15px;

      a {
        display: block;
        width: 100%;
        padding: 15px 0 !important;
        border-radius: 15px !important;
      }

      a:hover {
        background-color: $light-gray;
        color: $blue;
      }

      img, span {
        display: block;
        margin: 0 auto 15px auto;
      }

      img {
        height: 50px;
      }
    }
  }
}

// tablet size
@media (max-width: 1199px) {
  .home .developer .cta div a {
    padding: 15px !important;
  }
}

// mobile styles
@media (max-width: 767.98px) {
  .home {
    * {
      text-align: center;
    }

    button, .searchInput {
      margin-bottom: 10px;
    }

    form.searchBox.row {
      padding-top: 0 !important;
    }

    .results .searchResults {
      padding-top: 15px;

      h3 {
        margin-left: 0;
      }

      .register.btn-primary {
        width: 100%;
      }
    }

    .spacer {
      height: 50px
    }

    .imageWraper {
      margin-bottom: 15px;
    }

    .white {
      margin: 25px 0;

      p {
        max-width: 100%;
      }

      .imageWraper {
        min-height: auto !important;
      }
    }

    .developer {
      margin-left: 15px;
      margin-right: 15px;
    }
  }
}
