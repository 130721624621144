button:hover {
  box-shadow: none !important;
}

button:focus, input:focus {
  outline: none;
}

.rsk-input {
  width: 100%;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid $gray;
  padding: 5px 10px;
  margin: 10px 0;
}

.rsk-button {
  display:block;
  width: 100%;
  padding: 10px;
  background: $blue;
  color: $white;
  border-radius: 15px;
  border:none;
}

.rsk-button:hover {
  cursor: pointer;
}
